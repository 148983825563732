import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import "./index.css";

import LayoutWrapper from "./layoutWrapper";
import Chat from "./pages/chat/Chat";

initializeIcons();
const Eddie = React.lazy(() => import("./pages/eddie/Eddie"));
const router = createHashRouter([
    {
        path: "/",
        element: <LayoutWrapper />,
        children: [
            {
                index: true,
                path: "/",
                element: <Chat />
            },
            {
                path: "qa",
                lazy: () => import("./pages/ask/Ask")
            },
            {
                path: "eddie",
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Eddie />
                    </Suspense>
                )
            },
            {
                path: "testing",
                lazy: () => import("./pages/testing/Testing")
            },
            {
                path: "scores",
                lazy: () => import("./pages/reports/Reports")
            },
            {
                path: "cliented_logs",
                lazy: () => import("./pages/reports/Reports")
            },
            {
                path: "ally_logs",
                lazy: () => import("./pages/reports/Reports")
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
